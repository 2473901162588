var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoader
        ? _c("Loader", { attrs: { loading: _vm.isLoader } })
        : _c("div", { staticClass: "role-name" }, [
            _vm.roleName !== null
              ? _c("span", [
                  _c("b", [_vm._v("Role:")]),
                  _vm._v(" " + _vm._s(_vm.roleName.role))
                ])
              : _vm._e()
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }