<template>
  <div>
    <Loader :loading="isLoader" v-if="isLoader" />
    <div class="role-name" v-else><span v-if="roleName !==null"><b>Role:</b> {{ roleName.role }}</span></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    isLoader: true,
  }),
  components: {
    Loader: () => import(/* webpackPrefetch: true */ "../common/Loader"),
  },
  created() {
    setTimeout(() => {
      this.isLoader = false;
    }, 500);
  },
  computed: {
    ...mapGetters({
      roleName: "GET_USER_ROLE",
    }),
  },
};
</script>
<style lang="scss">
.role-name{
    text-align: center;
    margin: 0px 10px 15px 10px;
    width: 210px;
}
    
</style>
